@import '../../../../styles/variables';

.itemDriver {
  margin-bottom: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  height: auto;
  box-shadow: 0px 2px 6px rgba(151, 146, 146, 0.199);

  &.selected {
    background-color: $color-dark-3;
  }

  .avatarUser {
    width: 48px;
    height: 48px;
    position: relative;

    .avatar {
      width: 100%;
      height: 100%;
    }

    .dot {
      border-radius: 50%;
      position: absolute;
      bottom: -4px;
      right: -4px;
      width: 18px;
      height: 18px;
      z-index: 99;

      &.online {
        background-color: $color-driver-status-online;
        border: 2px solid $color-driver-status-online-border;
      }

      &.offline {
        background-color: $color-driver-status-offline;
        border: 2px solid $color-driver-status-offline-border;
      }

      &.busy {
        background-color: $color-driver-status-busy;
        border: 2px solid $color-driver-status-busy-border;
      }
    }
  }

  .content { 
    border-bottom: none !important;
    border: none; 
    width: 100%; }

  .more {
    width: fit-content;
    cursor: pointer;

    &:active { transform: scale(0.96); }

    .icon {
      margin-right: 4px;
    }

  }
  

  .moreInfo {
    width: 100%;
    display: none;
    padding: 8px 0;
    transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;

    &.active { 
      display: block;
      transition: all 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
    }
    
    .header {
      background: $color-dark;
      color: $color-white;
      padding: 4px 8px;
      margin: 4px 0;
      border-radius: 4px;
      width: 100%;
    }
  }
}