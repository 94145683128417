@import './aligns';
@import './variables';
@import './chat';
@import './picker-chat';
@import './components/index.scss';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;400;500;600;700&display=swap');
@import '~antd/dist/antd.dark.css';

@font-face {
  font-family: 'Evermore';
  src: url('https://uploads-ssl.webflow.com/60463e2ddc183d68d2db10ff/65205ef18f2f2eecef441628_Evermore.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.full{
  &__height { height: 100% !important; }
  &__width { width: 100% !important; }
}

.no {
  &__margin { margin: 0 !important; }
  &__padding { padding: 0 !important; }
  &__border { border: none !important; }
  &__outline { outline: none !important; }
}


body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-form-item-label {
  font-weight: 700;
}

.ant-input {
  font-weight: 500;
}

.ant-space-item {
  font-weight: 500;
  /* color: #3F4D70; */
}

.ant-table-tbody > tr > td {
  padding: 10px 16px;
}

@keyframes fadeIn {
	0% {
		transform: translateY(-25%);
	}
	100% {
		transform: translateY(0%);
	}		
}

.popup {
  animation-name: fadeIn;
  animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  left: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.popup li {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  padding: 5px 12px;
  transition: all .3s;
  white-space: nowrap;
  -webkit-transition: all .3s;
}

.popup li:hover {
  background-color: #e6f7ff;
}

.popup li > i {
  margin-right: 8px;
}

.booking-row {
  cursor: pointer;
}

.ant-pagination-options-size-changer .ant-select-selector {
  height: 32px !important;
  line-height: 32px !important;
}

.ant-pagination-options-size-changer .ant-select-selector .ant-select-selection-item {
  line-height: 32px !important;
}

.table-row-warning {
  background-color: #f651513d;
}

.user-table table {
  width: unset;
}

.status {
  border: solid 1.4px $color-neutral;
  border-radius: 2px;
  padding: 2px 4px;
  width: fit-content;

  &.small {
    font-size: 11px;
    border: solid 1px $color-neutral;
    text-transform: lowercase;
  }

  &.PAID, &.SOLVED, &.success, &.approved {
    border-color: $color-green;
    color: $color-green;
  }

  &.ACTIVE, &.info, &.active {
    border-color: $color-blue;
    color: $color-blue;
  }

  &.NOT_SOLVED, &.error, &.expired, &.failed, &.rejected {
    border-color: $color-error;
    color: $color-error;
  }

  &.REFUNDED {
    border-color: $color-purple;
    color: $color-purple;
  }

  &.warning {
    border-color: $color-yellow;
    color: $color-yellow;
  }
}

.card-filters {
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.ant-page-header-heading {
  align-items: center;
}

.selected {
  padding: 16px 24px;
  padding-right: 0;
  margin-bottom: 8px;
  border-left: 4px solid transparent;
  border-left-color: #00B2E3;
}

.attachment {
  .ant-card-body {
    padding: 12px;
  }
}


.booking {
  &__header {
      display: flex;
      flex-direction: column;
  }
  &__info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;
      &--text {
          font-size: 14px;
          font-weight: 500;
          &-label {
              font-size: 12px;
              color: $color-neutral;
          }
          &-value {
              color: $color-white;
              font-weight: 500;
              &.link {
                  color: $color-primary;
                  cursor: pointer;
              }
          }
      }
  }
}

.preview {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__layout {
    width: 100%;
    height: 200px;
    background-color: #4c4c4c;
    position: relative;
    
    &--sidebar {
      position: absolute;
      top: 12px;
      left: 12px;
      width: calc(15% - 24px);
      height: calc(100% - 24px);
      background-color: #585858;
    }
  
    &--content {
      position: absolute;
      top: 12px;
      right: 12px;
      width: calc(85% - 24px);
      height: calc(100% - 24px);
      background-color: $color-white;
      padding: 12px;
    }
  }
}

.ant-alert {
  padding: 8px;
  border-radius: 6px;
  color: $color-dark;

  &-message {
    color: $color-dark;
  }

  &-error {
    border-color: $color-error;
    background-color: $color-error-1;
  }

  &-warning {
    border-color: $color-yellow;
    background-color: $color-yellow-1;
  }

  &-info {
    border-color: $color-primary;
    background-color: $color-primary-2;
  }

  &-success {
    border-color: $color-green;
    background-color: $color-green-1;
  }

}

.text-logo {
  font-family: 'Evermore', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: $color-primary;
  margin-left: 24px;
}

.ant-collapse-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.marker-label {
  position: absolute;
  border: solid 1px #177DDC;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 500;

  &.left {
    left: 0px;
    bottom: 65px;
  }

  &.right {
    left: unset;
    right: 0px;
    top: 65px;
  }
}

.link {
  color: $color-link;
  cursor: pointer;
}