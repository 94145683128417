.time {
    font-size: 12px;
    color: white;
    border-radius: 10px;
    padding: 0 10px;
    background-color: rgb(118, 86, 133);
}

.inputTime {
    font-size: 12px;
    border-radius: 10px;
    padding: 0 10px;
    width: 30%;
}