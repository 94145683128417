//Primary Colors
$color-primary: #00B2E3 !default;
$color-primary-1: #B6EFFF !default;
$color-primary-2: #F0FCFF !default;

$color-green: #01EA74 !default;
$color-green-1: #CBFFE5 !default;

$color-dark: #3F4D70 !default;
$color-dark-1: #536288 !default;
$color-dark-2: #6E7B9C !default;
$color-dark-3: #1A1F36 !default;
$color-dark-4: #2a2b2b !default;

$color-white: #FFF !default;

$color-info:      #C9D6E8 !default;

//Secondary Colors
$color-yellow: #FFD875 !default;
$color-yellow-1: #FFEBA6 !default;
$color-blue: #3883FF !default;
$color-blue-1: #C0D8FF !default;
$color-purple: #6C8EEF !default;
$color-purple-1: #D1DDFF !default;

//Error Colors
$color-error: #FF426F !default;
$color-error-1: #FFBDD1 !default;

//Neutral Colors
$color-neutral: #C9D6E8 !default;
$color-neutral-1: #D3DFEE !default;
$color-neutral-2: #E1E8F1 !default;
$color-neutral-3: #F2F8FF !default;
$color_neutral_alt:      #F2F8FF !default;

// Drivers Status Color
$color-driver-status-busy: #ffeba6;
$color-driver-status-online: #00b2e3;
$color-driver-status-offline: #f0fcff;

$color-driver-status-busy-border: #e5a500;
$color-driver-status-online-border: #f0fcff;
$color-driver-status-offline-border: #3f4d70;

//Link Colors
$color-link: #177ddc !default;

$breakpoints: (
    xs:  380px,
    sm:  576px,
    md:  768px,
    lg:  992px,
    xl:  1200px,
    xxl: 1600px,
) !default;

$primary-color: (
  light-blue:#00B2E3,
  light-blue-1: #B6EFFF,
  light-blue-2: #F0FCFF,
  dark-blue:#3F4D70,
  dark-blue-1: #536288,
  dark-blue-2: #6E7B9C,
  dark-blue-3: #1A1F36,
  green: #01EA74,
  green-1: #CBFFE5,
  white: #FFF,
);

$secondary-color: (
  yellow: #FFD875,
  yellow-1: #FFEBA6,
  blue: #FFEBA6,
  blue-1: #C0D8FF,
  purple: #6C8EEF,
  purple-1: #D1DDFF,
);

$error-color: (
  error: #FF426F,
  error-1: #FFBDD1,
);

$neutral-color: (
  default: #C9D6E8,
  neutral-1: #D3DFEE,
  neutral-2: #E1E8F1,
  neutral-3: #F2F8FF,
);
