.chat {
    &__container {
        .ant-drawer-wrapper-body {
            background-color: #0B141A;
        }
    }

    &__header {
        margin-bottom: 16px;
        width: 100%;
    }

    &__create {
        width: 100%;
        gap: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__list {
        background-color: #c9d6e807;
        border-radius: 0px;
        padding: 8px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: 40vh;
        max-height: 45vh;
        gap: 20px;
        overflow-y: scroll;
    }

    &__send {
        width: 100%;
        margin-top: 12px;
        background-color: #c9d6e807;
        border-radius: 8px;
        padding: 12px;
    }

    &__attachments {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 8px;
        padding: 0;
    }

    &__empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        text-align: center;
        width: 100%;
        min-height: 30vh;
    }

    &__loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 10vh;
        gap: 8px;
    }

    &__quill {
        width: 100%;
        margin-bottom: 12px;
    }
}


.store {
    background-color: $color-dark;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 8px;
}

.message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 95%;
    min-width: 50%;
    max-width: 95%;
    padding: 6px 8px;
    border-radius: 12px;
    margin-right: auto;
    margin-left: 0;
    flex: initial;

    span, p {
        color: $color-white !important;
        margin-bottom: 0;
    }   

    a {
        color: $color-white !important;
        text-decoration: underline;
    }

    &.me {
        margin-left: auto;
        margin-right: 0;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-name {
            font-weight: 700;
            font-size: 14px;
        }
        &-icon {
            vertical-align: middle;
            svg {
                color: $color-dark;
            }
        }
    }

    &__description {
        width: 100%;
        
        &-text {
            margin-left: 0px;
        }
        
        &-time {
            margin: 0 !important;
            margin-right: 12px !important;
            font-size: 12px;
            text-align: right;
            width: 100%;
        }
    }

    &__avatar {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
    height: 200px;
    border-color: $color-neutral-2;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.ql-toolbar.ql-snow {
    border-color: $color-neutral-2 !important;
    background-color: $color-neutral-3 !important;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.ql-snow .ql-tooltip {
    left: 10px !important;
}

.settings__subheader {
    &--title {
        color: #FFD875 !important;
        font-weight: 500 !important;
    }

    &--help {
        background-color: #FFD875;
        border: solid 1.4px #FFD875;
        border-radius: 4px;
        padding: 4px 8px;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        margin: 18px 0px;

        svg {
            color: #FFD875
        }
    }
}