.screen {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-full {
        height: 100vh;
    }

    &-container {
        width: 100%;
        max-width: 500px;
        text-wrap: wrap;
        padding: 24px;
        border: 1px solid #080808;
        border-radius: 8px;
        background-color: #080808;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 24px;
    }

    &-remaining {
        height: calc(100vh - 64px);
        padding: 24px;
    }
}

.main-search {
    width: 584px;
    max-width: 584px;
    min-width: auto;
    padding-top: 6px;
    position: relative;
    margin: 0 auto;

    &__input {
        display: flex;
        z-index: 3;
        position: relative;
        min-height: 44px;
        border: 1px solid #5f6368;
        box-shadow: none;
        border-radius: 24px;
        margin: 0 auto;
        width: 638px;
        width: auto;
        max-width: 584px;

        textarea {
            resize: none;
            background-color: transparent;
            border: none;
            margin: 0;
            padding: 0;
            color: #e8eaed;
            word-wrap: break-word;
            outline: none;
            display: flex;
            line-height: 22px;
            overflow-x: hidden;

            &:focus, &:active {
                outline: none;
                border-color: transparent;
                box-shadow: none;
            }
        }
    }

    &__type {
        padding: 6px 12px;
        border-radius: 4px;
        background-color: #202124;
        font-weight: 600;
        cursor: pointer;

        &.active {
            background-color: $color-primary;
            span {
                color: $color-dark
            }
        }
    }

    &__results {
        margin-top: 16px;
        border-radius: 8px;
        position: relative;
        
        &--list {
            background-color: #020202;
            max-height: 400px;
            overflow-y: scroll;
            width: 100%;
            

            &-item {
                padding: 12px;
                margin-bottom: 8px;
                transition: all 0.2s ease-in-out;

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    background-color: #202124;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;
                }
            }
        }
    }


}