@import '../../../../styles/variables';

.driversLegend {
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    position: absolute;
    background-color: $color-neutral-2;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    z-index: 99;
    top: 93px;
    left: 24px;

    .legendOption {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 4px;
        color: $color-dark-3;

        .dot {
            border-radius: 50%;
            width: 18px;
            height: 18px;
            z-index: 99;
            margin-right: 4px;
      
            &.online {
              background-color: $color-driver-status-online;
              border: 2px solid $color-driver-status-online-border;
            }
      
            &.offline {
              background-color: $color-driver-status-offline;
              border: 2px solid $color-driver-status-offline-border;
            }
      
            &.busy {
              background-color: $color-driver-status-busy;
              border: 2px solid $color-driver-status-busy-border;
            }
        }
    }
}