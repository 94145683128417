.timeline {
    &__list {
        height: 650px;
        overflow-y: scroll;
        padding-right: 16px;

        .ant-steps-item-title {
            width: 100%;
            margin-bottom: 8px;
        }

    }
}
.card__event {
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: solid 1.4px #303030;
    border-radius: 4px;
    gap: 8px;
    margin: 8px;
    margin-top: 0;
    margin-left: 12px;
    cursor: pointer;
    width: 100%;
}

.card__tag {
    padding: 1px 6px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    border: solid 1px transparent;
    background-color: #303030;

    &.order {
        color: #00B2E3;
        border-color: #00B2E3;
        background-color: transparent;
    }

    &.event {
        color: #6C8EEF;
        border-color: #6C8EEF;
        background-color: transparent;
    }

    &.log {
        color: #6E7B9C;
        border-color: #6E7B9C;
        background-color: transparent;
    }

    &.FAILED {
        color: #f5222d;
        border-color: #f5222d;
        background-color: transparent;
    }

    &.SUCCESS {
        color: #52c41a;
        border-color: #52c41a;
        background-color: transparent;
    }
}

.card__code {
    background-color: #A1BDD914;
    color: #FFF;
    border-style: none;
    border-radius: 3px;
    display: inline;
    padding: 2px 4px;
    border-radius: 4px;
    overflow: auto;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}
