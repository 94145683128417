.picker-chat {
    &-screen {
        flex-direction: column;
    }
    &-result-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
    &-message-author {
        position: relative;
        .ant-btn {
            position: absolute;
            left: -30px;
            padding: 1px;
        }
        margin: 0 auto;
        max-width: 700px;
        font-weight: bold;
        &-assistant {
            color: #1890ff;
        }
    }
    &-message {
        margin: 0 auto 40px auto;
        max-width: 700px;
        white-space: pre-wrap;
    }
    &-loading {
        position: absolute;
        bottom: 0;
        left: 20px;
        display: flex;
        gap: 10px;
    }
    &-input {
        .ant-input-affix-wrapper {
            height: 50px;
            border-radius: 10px 0 0 10px!important;
        }
        .ant-input-search-button {
            height: 50px;
            width: 50px;
            border-radius: 0 10px 10px 0!important;
            .anticon {
                font-size: 30px;
            }
        }
    }
}